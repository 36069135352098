import { useDisclosure, Popover, PopoverTrigger, HStack, PopoverContent, PopoverCloseButton, PopoverBody, Stack, Text, Image, Box } from "@chakra-ui/react";
import { OfferEnquiry, Service } from "../../Marketplace/types";
import { getFirebaseImageUrl } from "../../../utils/firebase";
import { getServiceCategory } from "../../Bookings/WorkCategoryUtils";
import { Video } from "../../Video";
import { useState } from "react";

function reparationCategoryMapper(category: string) {
    if (category === 'reparation') {
        return 'Reparation'
    }
    if (category === 'troubleshoot') {
        return 'Felsökning'
    }
    if (category === 'inspection') {
        return 'Reparation enligt besiktningsprotokoll'
    }
}

const OfferEnquiryPopover: React.FC<{ selectedOfferEnquiry?: OfferEnquiry }> = ({ selectedOfferEnquiry }) => {
    const { onClose: onClosePopover, onOpen: onOpenPopover, isOpen: isOpenPopover } = useDisclosure();
    const [selectedOption, setSelectedOption] = useState<string | null>(null)
    const [enlargeImage, setEnlargeImage] = useState<boolean>(false);

    function renderService() {
        if (!selectedOfferEnquiry?.service) {
            return null
        }
        const serviceObject: Service = selectedOfferEnquiry?.service;
        const gearboxTranslate = serviceObject.gearbox === 'manual' ? 'Manuell' : 'Automat';

        return (
            <Stack>
                {getServiceCategory(serviceObject)}
                <HStack pt={2} spacing={1}>
                    <Text fontSize={12}>Miltal</Text>
                    <Text fontSize={12} as={'b'}>{serviceObject.mileage} mil</Text>
                </HStack>
                <HStack spacing={1}>
                    <Text fontSize={12}>Växellåda</Text>
                    <Text fontSize={12} as={'b'}>{gearboxTranslate}</Text>
                </HStack>

                {serviceObject?.comment && (
                    <HStack>
                        <Text fontSize={12}>Övrig kommentar</Text>
                        <Text fontSize={12} as={'b'}>{serviceObject.comment}</Text>
                    </HStack>
                )}
            </Stack>
        )
    }

    return (
        <Popover
            placement='bottom-start'
            isOpen={isOpenPopover}
            onOpen={onOpenPopover}
            onClose={onClosePopover}
        >
            <PopoverTrigger>
                <HStack width={'fit-content'}>
                    {selectedOfferEnquiry?.reparations?.map(rep => {
                        const title = reparationCategoryMapper(rep.category)
                        return <Text onClick={() => setSelectedOption(rep.category)} _hover={{ bg: 'teal.700' }} cursor={'pointer'} px={1.5} py={.5} bg={'teal'} color={'white'} borderRadius={'full'} fontSize={11}>{title}</Text>
                    })}
                    {selectedOfferEnquiry?.service && (
                        <Text onClick={() => setSelectedOption('service')} _hover={{ bg: 'teal.700' }} cursor={'pointer'} px={1.5} py={.5} bg={'teal'} color={'white'} borderRadius={'full'} fontSize={11}>Serviceoffert</Text>
                    )}
                </HStack>
            </PopoverTrigger>

            <PopoverContent p={5} boxShadow={'base'} borderRadius={16} w={'auto'} maxW={260}>
                <PopoverCloseButton m={1} />
                <PopoverBody p={0}>
                    <Stack spacing={2}>
                        {selectedOfferEnquiry?.comment && (
                            <Box>
                                <Text fontSize={12} as={'b'}>Kommentar</Text>
                                <Text fontSize={12}>{selectedOfferEnquiry?.comment}</Text>
                            </Box>
                        )}
                        {selectedOption === 'service' && renderService()}
                        {selectedOfferEnquiry?.reparations.map(rep => {
                            const isVideoAttachment = rep?.attachments[0]?.mimeType?.includes('video')
                            const isPdfAttachment = rep?.attachments[0]?.mimeType?.includes('pdf');
                            const attachmentFile = rep?.attachments[0]?.fileName;
                            if (selectedOption === rep.category) {
                                return (
                                    <Stack>
                                        {rep.description && (
                                            <Box>
                                                <Text fontSize={12} as='b'>Kundens problembeskrivning</Text>
                                                <Text fontSize={12}>{rep.description}</Text>
                                            </Box>
                                        )}
                                        <Box>
                                            <Text fontSize={12} as='b'>Kunden har bifogat följande</Text>
                                            {attachmentFile && isVideoAttachment ? (
                                                <Video selectedVideo={getFirebaseImageUrl(attachmentFile)} w={250} h={380} />
                                            ) : attachmentFile && isPdfAttachment ? (
                                                <embed src={getFirebaseImageUrl(attachmentFile)} width="100%" height="600px" type="application/pdf" />
                                            ) : attachmentFile && (
                                                <Image cursor={'pointer'} transition="transform 0.3s ease-in-out" transform={enlargeImage ? 'scale(1.5)' : 'scale(1)'} onClick={() => setEnlargeImage(!enlargeImage)} w={180} maxW={400} objectFit={'cover'} src={getFirebaseImageUrl(attachmentFile)} alt='Problembeskrivning' />
                                            )}
                                        </Box>
                                    </Stack>
                                )
                            }
                            return null;
                        })}
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default OfferEnquiryPopover;