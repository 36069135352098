import { useToast, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, HStack, Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { axiosPost } from "../../../hooks/api/utils";
import { OfferEnquiry } from "../../Marketplace/types";
import OfferForm from "./OfferFormBody";
import OfferSendSuccess from "./OfferSendSuccessBody";
import { FormProvider, useForm } from 'react-hook-form';
import { useCustomToast } from "../../../hooks/useCustomToast";
import LicensePlate from "../../LicensePlate";
import { useCompanyInfo, useCompanyServices } from "../../../hooks/api/companyInfo";
import { useHandledOfferRequestsImmutable } from "../../../hooks/api/offerRequests";
import { useCompanySettings, SETTINGS } from "../../../hooks/api/companySettings";

interface AddOfferConfirmationProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    mutateOfferRequests: () => void;
    enquiry: OfferEnquiry;
}

export function isWorkArticleFieldsUnFinished(data: any) {
    const { articleAmount, articleName, articlePrice } = data;

    if (articleName?.length > 0 && articleAmount?.length > 0 && articlePrice?.length > 0) {
        return true;
    }
    return false
}

const AddOfferConfirmation: React.FC<AddOfferConfirmationProps> = ({ isOpenModal, onCloseModal, mutateOfferRequests, enquiry }) => {
    const fileUploadInputRef = useRef(null);
    const toast = useToast();

    const [file, setFile] = useState(null);
    const [isLoadingSaveOffer, setIsLoadingSaveOffer] = useState(false);
    const [hasSavedSuccessfully, setHasSavedSuccessfully] = useState(false);
    const [handInDates, setHandInDates] = useState({});
    const [tab, setTab] = useState('form')
    const [unFinishedWorkArticleError, setUnfinishedWorkArticleError] = useState(false);
    const { mutateHandledOfferRequests } = useHandledOfferRequestsImmutable();
    const { mutateCompanyServices } = useCompanyServices();
    const { settingValue: addonArticlePercentageCost } = useCompanySettings(SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST);
    const isServiceWorkOnly = enquiry?.service && enquiry?.reparations?.length === 0;

    const articleRowRef = useRef(null);

    const { companyInfo } = useCompanyInfo();

    const methods = useForm();
    const { infoToast } = useCustomToast({ position: 'top-right' });

    useEffect(() => {
        return () => {
            setFile(null);
            setHasSavedSuccessfully(false);
            setIsLoadingSaveOffer(false);
        }
    }, [])

    function resetFields() {
        const { setValue } = methods;
        setValue('priceTotal', null)
        setValue('durationInDays', null)
        setFile(null)
        setHandInDates({})
    }

    function buildDefaultPayload(data: any) {
        const { durationInDays, availableTimeFrom, availableTimeTo } = data;

        let formData = new FormData();

        formData.append("clientId", enquiry.clientId);
        formData.append("companyName", companyInfo.companyName);
        formData.append("durationInDays", durationInDays);
        formData.append("availableTime", availableTimeFrom + '-' + availableTimeTo);
        formData.append('offerRequestId', enquiry.offerEnquiryId);
        formData.append('updatedStatus', "offerWaiting");
        formData.append('availableDates', JSON.stringify(handInDates)); // Maybe we can avoid stringify?

        return formData;
    }

    const saveOfferForm = async (data: any) => {
        if (isWorkArticleFieldsUnFinished(data)) {
            if (articleRowRef.current) {
                articleRowRef.current.focus()
            }
            setUnfinishedWorkArticleError(true)
            return
        }

        const { laborHours, laborCost, addedWork, durationInDays, availableTimeFrom, availableTimeTo } = data;

        if (isServiceWorkOnly) {
            if (!addedWork || addedWork?.length === 0) {
                return infoToast('Du behöver lägga till arbetsdetaljer')
            }
        }

        setIsLoadingSaveOffer(true)

        const payload = {
            clientId: enquiry.clientId,
            companyName: companyInfo.companyName,
            durationInDays,
            availableTime: availableTimeFrom + '-' + availableTimeTo,
            offerRequestId: enquiry.offerEnquiryId,
            updatedStatus: 'offerWaiting',
            availableDates: handInDates,
            laborHours,
            laborCost,
            workDetails: addedWork || [],
            addonArticlePercentageCost: addonArticlePercentageCost,
            isServiceWorkOnly
        }

        const { error, message } = isHandInDatesValid();
        if (error) {
            return infoToast(message)
        }

        try {
            await axiosPost('/api/offers/offers-v1/by-form', payload)
            await sendPushNotification();

            setIsLoadingSaveOffer(false)
            setUnfinishedWorkArticleError(false)
            setHasSavedSuccessfully(true)
            resetFields();
            mutateOfferRequests();
            mutateHandledOfferRequests();
            mutateCompanyServices();

        } catch (error) {
            setHasSavedSuccessfully(false)
            setIsLoadingSaveOffer(false)
            console.log(error);
            
            return toast({
                title: 'Något gick fel',
                position: 'top-right',
                description: "Kontrollera så du har fyllt i fälten korrekt",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const saveOfferFile = async (data: any) => {
        if (!file) {
            return infoToast('Vänligen lägg till en fil för arbetsdetaljer');
        }

        const { error, message } = isHandInDatesValid();
        if (error) {
            return infoToast(message)
        }

        setIsLoadingSaveOffer(true)

        const formData = buildDefaultPayload(data);
        formData.append("priceTotal", data.priceTotal);
        formData.append("file", file);

        try {
            await axiosPost('/api/offers/offers-v1', formData, {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            })

            await sendPushNotification();

            setIsLoadingSaveOffer(false)
            setHasSavedSuccessfully(true)
            resetFields();
            mutateOfferRequests();
            mutateHandledOfferRequests();

        } catch (error) {
            setHasSavedSuccessfully(false)
            setIsLoadingSaveOffer(false)
            return toast({
                title: 'Något gick fel',
                position: 'top-right',
                description: "Kontrollera så du har fyllt i fälten korrekt",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    function handleSaveOffer(data: any) {
        const saveByFileSelected = tab;
        if (saveByFileSelected === 'form') {
            return saveOfferForm(data)
        }

        return saveOfferFile(data)
    }


    async function sendPushNotification() {
        try {
            const payload = {
                userId: enquiry.clientId,
                source: 'offer_eniquiry_new_offer_added',
                messageTemplate: {
                    sound: 'default',
                    title: 'Du har fått en ny offert 🎉🎉🎉',
                    body: 'Öppna för att se din nya offert',
                    data: { withSome: 'data' },
                }
            }
            await axiosPost(`/api/user/push-notifications-v1/notification/send`, payload)
        } catch (error) {
            // ignore error
        }
    }

    function isHandInDatesValid() {
        if (Object.keys(handInDates).length === 0) {
            return { error: true, message: 'Vänligen välj inlämningsdatum i kalendern' }
        }
        return { error: false, message: null }
    }


    const renderDrawerBody = () => {
        if (!hasSavedSuccessfully) {
            return <OfferForm
                unFinishedWorkArticleError={unFinishedWorkArticleError}
                setUnfinishedWorkArticleError={setUnfinishedWorkArticleError}
                isLoadingSaveOfferFile={isLoadingSaveOffer}
                enquiry={enquiry}
                file={file}
                fileUploadInputRef={fileUploadInputRef}
                setFile={setFile}
                onSaveOffer={handleSaveOffer}
                setHandInDates={setHandInDates}
                handInDates={handInDates}
                tab={tab}
                setTab={setTab}
                articleRowRef={articleRowRef}
            />
        }

        if (hasSavedSuccessfully) {
            return <OfferSendSuccess onCloseModal={() => {
                onCloseModal();
                setHasSavedSuccessfully(false);
            }} />
        }
    }

    return (
        <>
            <Drawer
                isOpen={isOpenModal}
                onClose={() => {
                    onCloseModal()
                    setFile(null)
                    methods.setValue('priceTotal', null);
                    methods.setValue('durationInDays', null);
                    methods.setValue('availableTimeFrom', null);
                    methods.setValue('availableTimeTo', null);
                    setHasSavedSuccessfully(false);
                    setIsLoadingSaveOffer(false);
                }}
                size={'lg'}
            >
                <DrawerOverlay />
                <DrawerContent >
                    <DrawerCloseButton />
                    <DrawerHeader fontSize={16}>
                        <HStack justify={'space-between'} >
                            <Heading size={'sm'}>Ny offert</Heading>
                            <Box pr={12}>
                                <LicensePlate regNo={enquiry?.regNo} size='xs' />
                            </Box>
                        </HStack>
                    </DrawerHeader>
                    <DrawerBody padding={0}>
                        <FormProvider {...methods}>
                            {renderDrawerBody()}
                        </FormProvider>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default AddOfferConfirmation;
