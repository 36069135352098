import React, { useState } from 'react';
import Select from 'react-select';
import '../DrawerContent/CreateBookingForm.scss';
import { useCompanyServices } from '../../hooks/api/companyInfo';
import { HStack, Text, Button, Icon, Stack, Heading, useMediaQuery } from '@chakra-ui/react';
import { FaBolt } from 'react-icons/fa';
import { axiosPost } from '../../hooks/api/utils';
import { Endpoints } from '../../hooks/api/endpoints';
import CheckAnimation from '../Animations/CheckAnimation';
import { Controller, useFormContext } from 'react-hook-form';
import { WorkArticle } from '../Marketplace/types';
import { getCustomStyles } from './WorkArticlesSelect';

export const CustomServiceSelect: React.FC<{ handleInputChange: (val: string) => void, resetKey?: number, source?: 'offer' | 'additionalOffer' | 'booking' }> = ({ handleInputChange, resetKey, source }) => {
    const { companyServices, mutateCompanyServices } = useCompanyServices();
    const [, rerender] = useState({});
    const [isCompleted, setIsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const { control, formState: { errors }, getValues } = useFormContext()
    const [isMobile] = useMediaQuery('(max-width: 492px)')
    const addedWork = getValues('addedWork') as WorkArticle[]
    const isAdditionalOffer = source === 'additionalOffer';
    const isOffer = source === 'offer';

    async function handleSaveCustomWork(serviceName: string) {
        setIsLoading(true)
        try {
            await axiosPost(`${Endpoints.COMPANY_SERVICES}/custom`, { serviceName })
            await mutateCompanyServices();
            setIsLoading(false)
            setIsCompleted(true)
        } catch (error) {
            // Ignore
            setIsLoading(false)
        }
    }
    const validateOption = (value: string) => {
        if (isOffer || isAdditionalOffer && !value) {
            return true
        }

        const articleHasBeenAdded = value && addedWork?.some(item => item.valueType === 'work' && item.articleName === value);
        // Dont promt this on submit for additional work since validation differs
        if (!articleHasBeenAdded && !isAdditionalOffer) {
            return 'Lägg till med hjälp av plus-knappen till höger eller ta bort arbetet';
        }

        // No furthur validation when its an offer instead of booking
        if (isOffer || isAdditionalOffer) {
            return true;
        }

        // Finns inget arbete sparat i listan samt inget värde angets i dropdownen än
        if (!value && addedWork?.length !== 0) {
            return 'Du måste välja arbetet som du ska utföra här';
        }
        return true;
    };

    return (
        <Controller
            name="selectedOption"
            control={control}
            rules={{ validate: validateOption }}
            render={({ field }) => (
                <Stack pos={'relative'} w={'100%'} align='flex-start' spacing={1} zIndex={999999} data-testid="custom-service-select"
> 
                    {errors?.selectedOption?.message && (
                        <Text pos={'absolute'} top={-4} zIndex={99} fontSize={10} color={'red.500'}>
                            {errors?.selectedOption?.message as string}
                        </Text>
                    )}
                    <Select
                        {...field}
                        key={resetKey}
                        value={selectedOption?.value}
                        name="selectedOption"
                        onChange={selectedService => {
                            field.onChange(selectedService?.value);
                            if (selectedService?.value) {
                                handleInputChange(selectedService.value)
                                setSelectedOption(selectedService.value)
                            }
                            rerender({})
                        }}
                        styles={getCustomStyles(isMobile)}

                        noOptionsMessage={(obj) => (
                            <Stack>
                                <HStack>
                                    <Button isLoading={isLoading} onClick={() => handleSaveCustomWork(obj.inputValue)} rightIcon={<Icon as={FaBolt} size={4} />} size={'sm'} variant='secondary' borderRadius={16}>Kom ihåg arbetet</Button>
                                </HStack>
                                <HStack>
                                    <Text fontSize={14}>Spara ner arbetet:</Text>
                                    <Heading size={'xs'}>{obj.inputValue}</Heading>
                                    <CheckAnimation isChecked={isCompleted} h={20} w={20} />
                                </HStack>
                            </Stack>
                        )}
                        isClearable
                        options={companyServices?.customServices}
                        placeholder={'T.ex Felsökning / Däckbyte osv'}
                        className="basic-multi-select custom-service-select"
                        classNamePrefix="select"
                    />
                </Stack>
            )}
        />

    )
}